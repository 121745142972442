import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import NotFound from '../../components/NotFound';
import SmallSpinner from '../../components/SmallSpinner';

import { post } from '../../utils';
import { CLIENT_STATUS, ROLE, URLS } from '../../constants';

const MagicClientSignup = ({ login }) => {
  const { magic_code } = useParams();
  const navigate = useNavigate();
  const [validMagicCode, setValidMagicCode] = useState(false);
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [passwordError, setPasswordError] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    passLength: false,
    passwordMatch: false,
  });
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [type, setType] = useState('');
  const [willSkipContract, setWillSkipContract] = useState(null);
  const [willSkipAppointment, setWillSkipAppointment] = useState(null);

  useEffect(() => {
    const errors = {
      lowercase: !/(?=.*[a-z])/.test(password),
      uppercase: !/(?=.*[A-Z])/.test(password),
      number: !/(?=.*[0-9])/.test(password),
      special: !/(?=.*[!@#$%^&*()_+{}[\]:;<>,./?|-])/.test(password),
      passLength: password.length >= 11,
      passwordMatch:
        password === repeatPassword && (password || repeatPassword),
    };
    setPasswordError(errors);
  }, [password, repeatPassword]);

  useEffect(() => {
    const validateMagicCode = async () => {
      try {
        const response = await post(URLS.VALIDATE_CLIENT_MAGIC_CODE, {
          magic_code,
        });
        if (!response.ok) {
          setError('Magic code validation failed.');
          setLoading(false);
        } else {
          const data = await response.json();
          setEmail(data.email);
          setFullName(data.fullName);
          setType(data.type);
          setWillSkipContract(data.willSkipContract ?? false);
          setWillSkipAppointment(data.willSkipAppointment ?? false);
          setValidMagicCode(true);
          setLoading(false);
        }
      } catch (error) {
        setError('Invalid magic code.');
        setLoading(false);
      }
    };

    validateMagicCode();
  }, [magic_code]);

  const handleSignup = async () => {
    setSubmitting(true);
    try {
      const response = await post(URLS.CREATE_CLIENT, { email, password });
      if (!response.ok) {
        setError('User signup failed.');
        setSubmitting(false);
      } else {
        const data = await response.json();
        Cookies.set('clientToken', data.clientToken);
        Cookies.set('clientEmail', email);
        // const status = willSkipContract && !willSkipContract
        let status = CLIENT_STATUS.CONTRACT;
        if (willSkipContract && !willSkipAppointment) {
          status = CLIENT_STATUS.APPOINTMENT;
        } else if (willSkipContract && willSkipAppointment) {
          status = CLIENT_STATUS.PAYOUT;
        }
        const user = {
          email: email,
          token: data.clientToken,
          role: ROLE.CLIENT,
          status,
          type,
          fullName,
          installer: data.installer,
        };
        login(user);
        navigate('/account');
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setError(error.message);
      setSubmitting(false);
    }
  };

  const handleShowHideButton = (event) => {
    event.preventDefault();
    setHiddenPassword(!hiddenPassword);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSignup();
    }
  };

  return (
    <div className="min-h-screen h-full p-6 bg-gray-900">
      {validMagicCode ? (
        <div className="bg-gray-800 p-6 sm:p-8 rounded-lg shadow-lg max-w-lg mx-auto border border-gray-700">
          <p className="text-gray-300 text-sm mb-6">
            Hello{' '}
            <span className="font-semibold text-blue-400">{fullName}</span>,
            <br />
            Welcome to Rhythm Wireless!
            <br /> Please add a strong password to finish setting up your
            account for{' '}
            <span className="font-semibold text-blue-400">{email}</span>.
          </p>
          <form className="space-y-4">
            <input readOnly hidden autoComplete="username" value="" />
            <div className="relative">
              <input
                type={hiddenPassword ? 'password' : 'text'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                maxLength={40}
                autoComplete="new-password"
                className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800 text-white placeholder-gray-400"
              />
              <button
                tabIndex="-1"
                type="button"
                className="absolute top-1/2 right-4 transform -translate-y-1/2 text-sm text-gray-300 bg-gray-600 border border-gray-500 rounded-md px-3 py-1 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                onClick={handleShowHideButton}
              >
                {hiddenPassword ? 'Show' : 'Hide'}
              </button>
            </div>

            <input
              type="password"
              placeholder="Repeat Password"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              maxLength={40}
              autoComplete="new-password"
              onKeyDown={handleKeyDown}
              className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800 text-white placeholder-gray-400"
            />
            {submitting ? (
              <SmallSpinner />
            ) : (
              <button
                disabled={
                  !password ||
                  !repeatPassword ||
                  passwordError.special ||
                  passwordError.number ||
                  passwordError.uppercase ||
                  passwordError.lowercase ||
                  !passwordError.passLength ||
                  !passwordError.passwordMatch
                }
                onClick={handleSignup}
                className="w-full py-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800 disabled:bg-gray-600 disabled:cursor-not-allowed"
              >
                Sign Up
              </button>
            )}
          </form>

          <div className="mt-4 space-y-2 text-xs text-gray-400">
            <div className={`${passwordError.lowercase ? 'block' : 'hidden'}`}>
              Password must contain at least one lowercase letter.
            </div>
            <div className={`${passwordError.uppercase ? 'block' : 'hidden'}`}>
              Password must contain at least one uppercase letter.
            </div>
            <div className={`${passwordError.number ? 'block' : 'hidden'}`}>
              Password must contain at least one number.
            </div>
            <div className={`${passwordError.special ? 'block' : 'hidden'}`}>
              Password must contain at least one special character.
            </div>
            <div
              className={`${!passwordError.passLength ? 'block' : 'hidden'}`}
            >
              Password must be at least 11 characters long.
            </div>
            <div
              className={`${!passwordError.passwordMatch ? 'block' : 'hidden'}`}
            >
              Passwords must match.
            </div>
          </div>

          {error && <p className="mt-4 text-red-400 text-center">{error}</p>}
        </div>
      ) : loading ? (
        <div className="flex justify-center">
          <SmallSpinner />
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default MagicClientSignup;
