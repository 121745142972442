import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { fetchPriceHistory, convertBonestoUSD } from '../pages/admin/utils';

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const RewardsGraph = ({ rewards, scale }) => {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Rewards',
        data: [],
        borderColor: 'rgb(66, 121, 121)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  });

  // Add state for price history
  const [priceHistory, setPriceHistory] = useState(null);

  // Add useEffect to fetch price data
  useEffect(() => {
    const getPriceHistory = async () => {
      const data = await fetchPriceHistory();
      setPriceHistory(data);
    };

    getPriceHistory();
  }, []);

  useEffect(() => {
    if (rewards && priceHistory) {
      const labels = [];
      const data = [];

      rewards.forEach((reward) => {
        const date = new Date(reward.timestamp).toLocaleDateString();
        labels.push(date);

        const valueInUsd = convertBonestoUSD(reward.sum, priceHistory, date);
        data.push(valueInUsd);
      });

      setGraphData({
        labels,
        datasets: [
          {
            label: 'Rewards (USD)',
            data,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: true,
          },
        ],
      });
    }
  }, [priceHistory, rewards]);

  return (
    <div>
      <strong>
        Rewards {scale === '30' ? '(Last 30 Days)' : '(All time)'}
      </strong>
      <Line data={graphData} />
    </div>
  );
};

export default RewardsGraph;
