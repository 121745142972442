import { Link } from 'react-router-dom';

const { REACT_APP_FIREBASE_STORAGE_URL } = process.env;

const davidProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Fdavidprofile.png?alt=media&token=90eb7d1e-5299-452a-8940-cdcc867f6325`;
const amirLProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Famirprofile.jpeg?alt=media&token=826b3f64-6409-4c4e-a5b1-25b481ab03be`;
const hannahProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Fhannahprofile.png?alt=media&token=adcd32e0-6fc1-4361-b2cb-fb751232eb45`;
const abrahimProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2FabrahimProfile.jpg?alt=media&token=0bc8d48c-67e3-4a5b-9c7d-efc1c265f2e9`;
const georgeProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Fgeorgeprofile.jpg?alt=media&token=95d84e7b-f352-479a-90af-074877cf2faf`;
const brianProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2FbrianProflie.jpg?alt=media&token=2a66628e-9127-4ee2-b881-473b805d6ec2`;
const amirAProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2Famiraprofile.png?alt=media&token=b8b270cc-b8bb-4435-877a-311f459e5d2c`;
const peymanProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2FpeymanProfile.png?alt=media&token=1fdc2d7d-984e-4a94-af3a-e486a6215a3c`;
const samZProfileUrl = `${REACT_APP_FIREBASE_STORAGE_URL}team_photos%2FsamzProfile.png?alt=media&token=3debb392-ec05-49c4-8afa-0f4d884e53c2`;

const teamMembers = [
  {
    name: 'David Freedland',
    title: 'Founder and CEO',
    about:
      'David began his career doing research and product design in the decentralized payments space. He then led business development for the largest non-OEM hardware and solutions distributor in the DeWi industry, where he became a leading expert in deploying decentralized wireless infrastructure. David continues to consult seed-stage projects in the Web3 and De-Fi space, while primarily pursuing his dream of decentralizing telecom.',
    location: 'San Francisco, CA',
    profilePic: davidProfileUrl,
  },
  {
    name: 'Amir Luria',
    title: 'Co-Founder',
    about:
      'Amir began his banking career at Goldman Sachs, where he worked on the Prime Brokerage desk. He now works in the Venture-backed securities space, enabling liquidity in Pre-IPO markets while also dedicating his off-hours to the deployment of L1 Web3 platforms.',
    location: 'San Diego, CA',
    profilePic: amirLProfileUrl,
  },
  {
    name: 'Amir Ali Barani',
    title: 'Deployments and Network Coordinator',
    about:
      'As the founder of CBRS store, Amir is no stranger to decentralized wireless. He has managed commercial installations of various networking equipment on towers, billboards, and buildings. His hardware expertise and deep industry knowledge are the secret sauce behind our rooftop deployments.',
    location: 'Los Angeles, CA',
    profilePic: amirAProfileUrl,
  },
  {
    name: 'Hannah Olsen',
    title: 'Business Development Executive, Lead',
    about:
      'Hannah has built a brand and career in the LA music industry, working with bars, clubs, concert venues and of course famous artists and influencers. This deep network is a force to be reckoned with. On top if this, Hannah was the marketing guru of the viral platform NoCap Shows.',
    location: 'Los Angeles, CA',
    profilePic: hannahProfileUrl,
  },
  {
    name: 'Peyman Mehrban',
    title: 'Solutions Engineer',
    about:
      'Peyman has been an active deployer across multiple DeWi networks. As a partner at CBRS Store, he is experienced with complex installations of various kinds of networking equipment.',
    location: 'Los Angeles, CA',
    profilePic: peymanProfileUrl,
  },
  {
    name: 'Abrahim Adewunmi',
    title: 'Business Development Executive, Regional Lead',
    about:
      "Over 6+ years of experience in Tech Sales, Business Development, and Crypto industry. He brings a unique blend of skills and experiences. A Master's in Blockchain and Digital Currency, the 1st recipient of the Inaugural Coinbase UNIC full-ride scholarship, showcasing my commitment to excellence and innovation in tech. Experience directly with startups and large enterprise-level brands like Yelp, Beyond, and Paystand.",
    location: 'Oakland, CA',
    profilePic: abrahimProfileUrl,
  },
  {
    name: 'George Freedland',
    title: 'Software Engineer, IOS & Web',
    about:
      'George is a seasoned full-stack software developer renowned for his adeptness in crafting innovative solutions for a wide array of web and mobile applications. With an extensive background spanning across both public and private sectors, he has demonstrated a remarkable ability to tackle complex challenges and deliver impactful results.',
    location: 'San Francisco, CA',
    profilePic: georgeProfileUrl,
  },
  {
    name: 'Brian Dorsey',
    title: 'Graphic Design and Marketing',
    about:
      'Brian currently works in the advertising industry for clients such as Amazon, Bosch, and LinkedIn. His work includes advertising campaigns, UI/UX, and art direction. Brian also works as a freelance designer specializing in nonprofits and early-stage startups.',
    location: 'Los Angeles, CA',
    profilePic: brianProfileUrl,
  },
  {
    name: 'Sam Zarling',
    title: 'Deployment Operations Specialist',
    about:
      'Sam is our first hire specializing in technical hardware solutions and fleet management. With his expertise, he ensures smooth deployment operations.',
    location: 'San Francisco, CA',
    profilePic: samZProfileUrl,
  },
];

const OurTeam = () => (
  <div className="py-16 bg-gray-50" id="ourTeam">
    <section>
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
        Meet Our Team
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-12 px-4">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
          >
            <img
              src={member.profilePic}
              alt={`${member.name} Profile`}
              className="w-32 h-32 mx-auto rounded-full border-4 border-green-500 mb-4"
            />
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              {member.name}
            </h3>
            <p className="text-sm text-gray-500 mb-4">{member.title}</p>
            <p className="text-sm text-gray-700 mb-4">{member.about}</p>
            <p className="text-sm text-green-500">{member.location}</p>
          </div>
        ))}
      </div>
    </section>
    <div className="text-center mt-12">
      <Link to="/" className="text-blue-600 hover:underline text-lg">
        Go back to home
      </Link>
    </div>
  </div>
);

export default OurTeam;
