import { useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import SmallSpinner from '../../components/SmallSpinner';
import { URLS } from '../../constants';
import { post } from '../../utils';

const AdminInvite = () => {
  const [toEmail, setToEmail] = useState('');
  const [role, setRole] = useState('ADMIN');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const token = Cookies.get('token');
  const username = Cookies.get('username');
  const email = Cookies.get('email');

  const handleEmailChange = (e) => {
    setToEmail(e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleInvite = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const result = await post(URLS.INVITE_INFRA_USER, {
        token,
        email,
        username,
        toEmail,
        role,
      });
      if (result.status === 200) {
        setSubmitted(true);
        setSubmitting(false);
      }
    } catch (error) {
      console.error('Error in handleInvite: ', error.message);
    }
    setSubmitting(false);
  };

  return (
    <div className="p-6 bg-white shadow-lg rounded-lg">
      <Link
        to="/admin/users"
        className="text-blue-600 hover:text-blue-800 font-medium mb-6 inline-block"
      >
        Back to Users
      </Link>

      <form
        onSubmit={handleInvite}
        className="space-y-6 bg-gray-50 p-6 rounded-lg shadow-md"
      >
        <h2 className="text-2xl font-semibold text-gray-800">Invite User</h2>

        {submitted ? (
          <p className="text-center text-gray-600">
            Invite sent to <b>{toEmail}</b>
            <br />
            for role: <b>{role}</b>
          </p>
        ) : submitting ? (
          <div className="flex justify-center">
            <SmallSpinner />
          </div>
        ) : (
          <>
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-medium mb-2"
              >
                Email:
              </label>
              <input
                id="email"
                type="email"
                value={toEmail}
                onChange={handleEmailChange}
                required
                placeholder="Enter Email"
                maxLength={40}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label
                htmlFor="role"
                className="block text-gray-700 font-medium mb-2"
              >
                Role:
              </label>
              <select
                id="role"
                value={role}
                onChange={handleRoleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="ADMIN">ADMIN</option>
                <option value="SALES">SALES</option>
                <option value="OPS">OPS</option>
                <option value="INSTALLER">INSTALLER</option>
              </select>
            </div>

            <button
              type="submit"
              disabled={!toEmail}
              className="w-full py-3 text-white bg-blue-600 hover:bg-blue-700 font-medium rounded-lg disabled:bg-gray-400 disabled:cursor-not-allowed disabled:text-gray-300"
            >
              Invite
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default AdminInvite;
