import { Link } from 'react-router-dom';
import NotFound from '../../components/NotFound';
import { ROLE } from '../../constants';

const AdminDashboard = ({ user }) => {
  if (!user || !user.role) {
    return <NotFound />;
  }

  const { role } = user;

  const renderAdminFunctions = () => (
    <>
      <hr className="my-4 border-gray-300" />
      <h3 className="text-lg font-semibold text-gray-700">Admin Functions</h3>
      <ul className="space-y-2 mt-2">
        <Link to="/admin/users" className="block text-blue-500 hover:underline">
          Internal Users
        </Link>
        <Link
          to="/admin/client-documents"
          className="block text-blue-500 hover:underline"
        >
          Client Documents
        </Link>
        <Link
          to="/admin/manage-devices"
          className="block text-blue-500 hover:underline"
        >
          Manage Devices
        </Link>
        <Link
          to="/admin/mercury-dashboard"
          className="block text-blue-500 hover:underline"
        >
          Mercury Dashboard
        </Link>
      </ul>
    </>
  );

  const renderSalesFunctions = () => (
    <>
      <hr className="my-4 border-gray-300" />
      <h3 className="text-lg font-semibold text-gray-700">Sales Functions</h3>
      <ul className="space-y-2 mt-2">
        <Link
          to="/admin/potential-household-clients"
          className="block text-blue-500 hover:underline"
        >
          Potential Household Clients
        </Link>
        <Link
          to="/admin/potential-business-clients"
          className="block text-blue-500 hover:underline"
        >
          Potential Business Clients
        </Link>
        <Link
          to="/admin/active-clients"
          className="block text-blue-500 hover:underline"
        >
          Active Clients
        </Link>
        <Link
          to="/admin/manage-ops-schedule"
          className="block text-blue-500 hover:underline"
        >
          My Installation Schedule
        </Link>
      </ul>
    </>
  );

  const renderOpsFunctions = () => (
    <>
      <hr className="my-4 border-gray-300" />
      <h3 className="text-lg font-semibold text-gray-700">Ops Functions</h3>
      <ul className="space-y-2 mt-2">
        <Link
          to="/admin/manage-ops-potential-sites"
          className="block text-blue-500 hover:underline"
        >
          Approve/Reject New Potential Sites
        </Link>
        <Link
          to="/admin/assign-installer-active-sites"
          className="block text-blue-500 hover:underline"
        >
          Approve/Reject Sites Added to Existing Accounts
        </Link>
        <Link
          to="/admin/manage-ops-schedule"
          className="block text-blue-500 hover:underline"
        >
          Manage My Schedule
        </Link>
        <Link
          to="/admin/manage-ops-sites"
          className="block text-blue-500 hover:underline"
        >
          My Accounts (Where I am the Ops Rep)
        </Link>
      </ul>
    </>
  );

  const renderInstallerFunctions = () => (
    <>
      <hr className="my-4 border-gray-300" />
      <h3 className="text-lg font-semibold text-gray-700">
        Installer Functions
      </h3>
      <ul className="space-y-2 mt-2">
        <Link
          to="/admin/manage-ops-schedule"
          className="block text-blue-500 hover:underline"
        >
          Manage My Schedule
        </Link>
        <Link
          to="/admin/manage-installer-sites"
          className="block text-blue-500 hover:underline"
        >
          My Accounts (Where I am the Installer)
        </Link>
      </ul>
    </>
  );

  const renderBasedOnRole = () => {
    switch (role) {
      case ROLE.ADMIN:
        return (
          <>
            {renderAdminFunctions()}
            {renderSalesFunctions()}
            {renderOpsFunctions()}
            {renderInstallerFunctions()}
          </>
        );
      case ROLE.SALES:
        return renderSalesFunctions();
      case ROLE.OPS:
        return renderOpsFunctions();
      case ROLE.INSTALLER:
        return renderInstallerFunctions();
      default:
        return <NotFound />;
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-md border border-gray-200">
      <h3 className="text-xl font-bold text-gray-800">
        Hi <span className="text-blue-500">{user.email}</span>!
      </h3>
      <h2 className="text-2xl font-bold text-gray-800 mt-2">Admin Dashboard</h2>
      {renderBasedOnRole()}
    </div>
  );
};

export default AdminDashboard;
