import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SmallSpinner from '../../components/SmallSpinner';
import { adminPost } from '../../utils';
import { URLS } from '../../constants';

export default function MercuryDashboard() {
  const [recipients, setRecipients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeClients, setActiveClients] = useState([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchRecipients = async () => {
      try {
        setLoading(true);
        const response = await adminPost(URLS.GET_RECIPIENTS);
        if (!response.ok) {
          console.error('Failed to fetch recipients');
        } else {
          const data = await response.json();
          setRecipients(data.recipients);
        }
      } catch (error) {
        console.error('Error fetching recipients:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchActiveClients = async () => {
      try {
        setClientsLoading(true);
        const response = await adminPost(URLS.GET_ACTIVE_CLIENTS, {
          page: currentPage,
        });
        if (!response.ok) {
          console.error('Failed to fetch active clients');
        } else {
          const data = await response.json();
          console.log('Active clients:', data);
          setActiveClients(data.users);
          setTotalPages(data.totalPages);
        }
      } catch (error) {
        console.error('Error fetching active clients:', error);
      } finally {
        setClientsLoading(false);
      }
    };

    fetchRecipients();
    fetchActiveClients();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="p-6 bg-white shadow-lg rounded-lg">
      <Link
        to="/admin/dashboard"
        className="text-blue-600 hover:text-blue-800 font-medium mb-6 inline-block"
      >
        Back To Dashboard
      </Link>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Mercury Recipients
      </h2>

      <hr className="border-t-2 border-gray-300 mb-6" />

      {loading ? (
        <div className="flex justify-center">
          <SmallSpinner />
        </div>
      ) : !recipients.length ? (
        <p className="text-center text-gray-500">No recipients found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg border border-gray-300">
            <thead className="bg-gray-100 text-left">
              <tr>
                <th className="px-4 py-2 font-medium text-gray-600">Name</th>
                <th className="px-4 py-2 font-medium text-gray-600">Status</th>
                <th className="px-4 py-2 font-medium text-gray-600">
                  Last Paid
                </th>
              </tr>
            </thead>
            <tbody>
              {recipients.map((recipient) => (
                <tr key={recipient.id} className="border-t">
                  <td className="px-4 py-2 text-gray-800">{recipient.name}</td>
                  <td className="px-4 py-2 text-gray-800">
                    {recipient.status}
                  </td>
                  <td className="px-4 py-2 text-gray-800">
                    {recipient.dateLastPaid
                      ? new Date(recipient.dateLastPaid).toLocaleDateString()
                      : 'Never'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        Active Clients
      </h2>

      <hr className="border-t-2 border-gray-300 mb-6" />

      {clientsLoading ? (
        <div className="flex justify-center">
          <SmallSpinner />
        </div>
      ) : !activeClients || !activeClients.length ? (
        <p className="text-center text-gray-500">No active clients found.</p>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg border border-gray-300">
              <thead className="bg-gray-100 text-left">
                <tr>
                  <th className="px-4 py-2 font-medium text-gray-600">Name</th>
                  <th className="px-4 py-2 font-medium text-gray-600">Email</th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Status
                  </th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Payout Option
                  </th>
                </tr>
              </thead>
              <tbody>
                {activeClients.map((client) => (
                  <tr key={client.id} className="border-t">
                    <td className="px-4 py-2 text-gray-800">
                      {client.fullName}
                    </td>
                    <td className="px-4 py-2 text-gray-800">{client.email}</td>
                    <td className="px-4 py-2 text-gray-800">{client.status}</td>
                    <td className="px-4 py-2 text-gray-800">
                      {client.stripeId
                        ? 'Stripe'
                        : client.mercuryId
                          ? 'Mercury'
                          : 'No payout option'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-center mt-4 gap-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-4 py-2 border rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Previous
            </button>
            <span className="px-4 py-2">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-4 py-2 border rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}
