import { useState } from 'react';
import Cookies from 'js-cookie';

import { URLS } from '../../constants';
import { post } from '../../utils';
import { Link } from 'react-router-dom';
import SmallSpinner from '../../components/SmallSpinner';

const AdminSignIn = () => {
  const [adminEmail, setAdminEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(true);

  const handleSignIn = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const response = await post(URLS.ADMIN_SIGNIN, { adminEmail, password });

      if (!response.ok) {
        setErrorMessage('Invalid email or password');
        setSubmitting(false);
        return;
      }

      const data = await response.json();

      Cookies.set('token', data.token);
      Cookies.set('email', data.email);
      window.location.href = '/admin/dashboard';
    } catch (error) {
      setSubmitting(false);
      setErrorMessage(error.message);
    }
  };

  const handleShowHideButton = (event) => {
    if (event.key === 'Enter') {
      return;
    }
    event.preventDefault();
    setHiddenPassword(!hiddenPassword);
  };

  return (
    <div className="flex justify-center bg-gray-50 p-6">
      <form
        onSubmit={handleSignIn}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md space-y-6"
      >
        <h2 className="text-3xl font-semibold text-gray-800">Admin Sign In</h2>

        {errorMessage && (
          <div className="text-red-600 text-sm font-medium">{errorMessage}</div>
        )}

        <input
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="email"
          placeholder="Email"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
          maxLength={40}
          autoComplete="username"
        />

        <div className="relative">
          <input
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            type={hiddenPassword ? 'password' : 'text'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            maxLength={40}
            autoComplete="current-password"
          />
          <button
            type="button"
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={handleShowHideButton}
            onKeyDown={handleShowHideButton}
            tabIndex="-1"
          >
            {hiddenPassword ? 'Show' : 'Hide'}
          </button>
        </div>

        {!submitting ? (
          <button
            disabled={!adminEmail || !password}
            className="w-full py-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none"
          >
            Sign In
          </button>
        ) : (
          <div className="flex justify-center">
            <SmallSpinner />
          </div>
        )}

        <Link
          to="/admin/forgot-password"
          className="block text-center text-blue-600 hover:text-blue-800 text-sm"
        >
          Forgot password?
        </Link>
      </form>
    </div>
  );
};

export default AdminSignIn;
