import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cookies from 'js-cookie';
import { removeAllCookies } from './utils';
import './App.css';

import ContactUs from './components/ContactUs';
import Header from './components/Header';
import NotFound from './components/NotFound';
import SmallSpinner from './components/SmallSpinner';

import Faq from './pages/home/Faq';
import HowItWorks from './pages/home/HowItWorks';
import InternetReimagined from './pages/home/InternetReimagined';
import Intro from './pages/home/Intro';
import MissionStatement from './pages/home/MissionStatement';

import Careers from './pages/careers/Careers';
import Job from './pages/careers/Job';

import Referrals from './pages/referrals/Referrals';

import Businesses from './pages/signup/Businesses';
import Household from './pages/signup/Household';

import OurTeam from './pages/team/OurTeam';

import Terms from './pages/termsAndConditions/Terms';

import AdminSignIn from './pages/admin/AdminSignIn';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminUsers from './pages/admin/AdminUsers';
import AdminInvite from './pages/admin/AdminInvite';
import MagicClientSignup from './pages/admin/MagicClientSignup';
import MagicInfraSignup from './pages/admin/MagicInfraSignup';
import PotentialHouseholdClients from './pages/admin/PotentialHouseholdClients';
import PotentialHouseholdClientsAdd from './pages/admin/PotentialHouseholdClientsAdd';
import PotentialBusinessClients from './pages/admin/PotentialBusinessClients';
import PotentialBusinessClientsAdd from './pages/admin/PotentialBusinessClientsAdd';
import ManageOpsPotentialSites from './pages/admin/ManageOpsPotentialSites';
import ManageOpsSites from './pages/admin/ManageOpsSites';
import ManageInstallerSites from './pages/admin/ManageInstallerSites';
import ManageDevices from './pages/admin/ManageDevices';
import MercuryDashboard from './pages/admin/MercuryDashboard';

import Account from './pages/user/Account';

import { INFRA_ROLES, ROLE, TOKEN_TYPE, URLS } from './constants';
import { post } from './utils';
import OpsManageSchedule from './pages/admin/OpsManageSchedule';
import ClientDocuments from './pages/admin/ClientDocuments';
import ActiveClients from './pages/admin/ActiveClients';
import ActiveClientsDetails from './pages/admin/ActiveClientsDetails';
import AssignInstallerActiveSites from './pages/admin/AssignInstallerActiveSites';
import AdminForgotPassword from './pages/admin/AdminForgotPassword';
import MagicInfraSignupForgot from './pages/admin/MagicInfraSignupForgot';
import UserForgotPassword from './pages/user/UserForgotPassword';
import UserResetPassword from './pages/user/UserResetPassword';

const App = () => {
  const [admin, setAdmin] = useState(null);
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [adminAuthenticating, setAdminAuthenticating] = useState(false);
  const [clientAuthenticating, setClientAuthenticating] = useState(false);
  const [client, setClient] = useState(null);

  const updateClient = (user) => {
    if (!user) return;

    const updatedClient = client ? { ...client } : {};

    Object.keys(user).forEach((key) => {
      updatedClient[key] = user[key];
    });

    setClient(updatedClient);
  };

  const updateAdmin = (user) => {
    if (!user) return;

    const updatedClient = admin ? { ...admin } : {};

    Object.keys(user).forEach((key) => {
      updatedClient[key] = user[key];
    });

    setAdmin(updatedClient);
  };

  const toggleSignupModal = () => {
    setSignupModalOpen(!signupModalOpen);
  };

  const toggleLoginModal = () => {
    setLoginModalOpen(!loginModalOpen);
  };

  const loginUser = (user) => {
    setAdmin(user);
    setAdminAuthenticating(false);
  };

  const loginClient = (client) => {
    setClient(client);
    setClientAuthenticating(false);
  };

  const logout = async () => {
    const token = Cookies.get('token');
    const email = Cookies.get('email');
    const clientToken = Cookies.get('clientToken');
    const clientEmail = Cookies.get('clientEmail');
    if (token && email) {
      await post(URLS.INFRA_LOGOUT, { token, email });
    }
    if (clientToken && clientEmail) {
      await post(URLS.CLIENT_LOGOUT, { clientToken, clientEmail });
    }
    removeAllCookies();
    setAdmin(null);
    setClient(null);
    setAdminAuthenticating(false);
    setClientAuthenticating(false);
  };

  useEffect(() => {
    const token = Cookies.get('token');
    const email = Cookies.get('email');
    const clientEmail = Cookies.get('clientEmail');
    const clientToken = Cookies.get('clientToken');

    if (clientToken && clientEmail) {
      setClientAuthenticating(true);
      validateToken(TOKEN_TYPE.CLIENT, clientToken, clientEmail);
    }
    if (token && email) {
      setAdminAuthenticating(true);
      validateToken(TOKEN_TYPE.INFRA, token, email);
    }

    if ((!token || !email) && (!clientToken || !clientEmail)) {
      removeAllCookies();
      setAdminAuthenticating(false);
      setClientAuthenticating(false);
    }
  }, []);

  const validateToken = async (tokenType, token, email) => {
    const url =
      tokenType === TOKEN_TYPE.INFRA
        ? URLS.VALIDATE_INFRA_TOKEN
        : URLS.VALIDATE_CLIENT_TOKEN;
    try {
      const response = await post(url, { token, email });
      if (response.ok) {
        const responseData = await response.json();
        const { user } = responseData;
        if (tokenType === TOKEN_TYPE.CLIENT) {
          setClient(user);
          setClientAuthenticating(false);
        } else {
          setAdmin(user);
          setAdminAuthenticating(false);
        }
      } else {
        removeAllCookies();
        setAdminAuthenticating(false);
        setClientAuthenticating(false);
      }
    } catch (error) {
      console.error('Error validating token:', error);
      removeAllCookies();
      setAdminAuthenticating(false);
      setClientAuthenticating(false);
    }
  };

  return (
    <Router>
      <>
        <Header
          signupModalOpen={signupModalOpen}
          toggleSignupModal={toggleSignupModal}
          loginModalOpen={loginModalOpen}
          toggleLoginModal={toggleLoginModal}
          loginClient={loginClient}
          client={client}
          admin={admin}
          logout={logout}
          clientLoading={clientAuthenticating}
          adminLoading={adminAuthenticating}
        />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home
                signupModalOpen={signupModalOpen}
                toggleSignupModal={toggleSignupModal}
                loginModalOpen={loginModalOpen}
                toggleLoginModal={toggleLoginModal}
                loginClient={loginClient}
              />
            }
          />
          <Route path="/household" element={<Household />} />
          <Route path="/business" element={<Businesses />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/referrals" element={<Referrals />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/jobs/:job_name" element={<Job />} />
          <Route
            path="/signup/:magic_code"
            element={<MagicInfraSignup login={loginUser} />}
          />
          <Route
            path="/create-account/:magic_code"
            element={<MagicClientSignup login={loginClient} />}
          />
          <Route path="/admin" element={<AdminSignIn />} />
          <Route
            path="/admin/forgot-password"
            element={<AdminForgotPassword />}
          />
          <Route
            path="/admin/forgot-password/:magic_code"
            element={<MagicInfraSignupForgot login={loginUser} />}
          />
          <Route
            path="/forgot-password/:magic_code"
            element={<UserResetPassword login={loginClient} />}
          />
          <Route path="/forgot-password" element={<UserForgotPassword />} />
          {client && (
            <Route
              path="/account"
              element={
                <Account
                  client={client}
                  setClient={(user) => updateClient(user)}
                />
              }
            />
          )}
          {admin && INFRA_ROLES.includes(admin.role) && (
            <Route
              path="/admin/dashboard"
              element={<AdminDashboard user={admin} />}
            />
          )}
          {admin && admin.role === ROLE.ADMIN && (
            <>
              <Route path="/admin/users" element={<AdminUsers />} />
              <Route path="/admin/invite-user" element={<AdminInvite />} />
              <Route
                path="/admin/client-documents"
                element={<ClientDocuments />}
              />
              <Route path="/admin/manage-devices" element={<ManageDevices />} />
              <Route
                path="/admin/mercury-dashboard"
                element={<MercuryDashboard />}
              />
            </>
          )}
          {admin && [ROLE.ADMIN, ROLE.SALES].includes(admin.role) && (
            <>
              <Route
                path="/admin/potential-household-clients"
                element={<PotentialHouseholdClients />}
              />
              <Route
                path="/admin/potential-household-clients/add"
                element={<PotentialHouseholdClientsAdd />}
              />
              <Route
                path="/admin/potential-business-clients"
                element={<PotentialBusinessClients />}
              />
              <Route
                path="/admin/potential-business-clients/add"
                element={<PotentialBusinessClientsAdd />}
              />
              <Route
                path="/admin/active-clients/details/:userid"
                element={<ActiveClientsDetails />}
              />
              <Route path="/admin/active-clients" element={<ActiveClients />} />
              <Route
                path="/admin/manage-ops-schedule"
                element={
                  <OpsManageSchedule
                    user={admin}
                    setUser={(user) => updateAdmin(user)}
                  />
                }
              />
            </>
          )}
          {admin && [ROLE.ADMIN, ROLE.OPS].includes(admin.role) && (
            <>
              <Route
                path="/admin/manage-ops-potential-sites"
                element={<ManageOpsPotentialSites />}
              />
              <Route
                path="/admin/manage-ops-sites"
                element={<ManageOpsSites />}
              />
              <Route
                path="/admin/assign-installer-active-sites"
                element={<AssignInstallerActiveSites />}
              />
            </>
          )}
          {admin && [ROLE.ADMIN, ROLE.INSTALLER].includes(admin.role) && (
            <Route
              path="/admin/manage-installer-sites"
              element={<ManageInstallerSites />}
            />
          )}
          {admin &&
            [ROLE.ADMIN, ROLE.OPS, ROLE.INSTALLER].includes(admin.role) && (
              <Route
                path="/admin/manage-ops-schedule"
                element={
                  <OpsManageSchedule
                    user={admin}
                    setUser={(user) => updateAdmin(user)}
                  />
                }
              />
            )}
          {adminAuthenticating || clientAuthenticating ? (
            <Route path="*" element={<SmallSpinner />} />
          ) : (
            <Route path="*" element={<NotFound />} />
          )}
        </Routes>
      </>
    </Router>
  );
};

const Home = ({
  signupModalOpen,
  toggleSignupModal,
  loginModalOpen,
  toggleLoginModal,
  loginClient,
}) => (
  <>
    <Intro
      signupModalOpen={signupModalOpen}
      toggleSignupModal={toggleSignupModal}
      loginModalOpen={loginModalOpen}
      toggleLoginModal={toggleLoginModal}
      loginClient={loginClient}
    />
    <InternetReimagined />
    <MissionStatement />
    <HowItWorks />
    <Faq />
    <ContactUs />
  </>
);

export default App;
