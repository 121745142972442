import { useState } from 'react';
import { clientPost } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../constants';
// import { useStripeConnect } from './useStripeConnect';
// import {
//   ConnectAccountOnboarding,
//   ConnectComponentsProvider,
// } from '@stripe/react-connect-js';
import './Onboarding.css';

const PayoutPage = ({ setClient }) => {
  // const [accountCreatePending, setAccountCreatePending] = useState(false);
  // const [onboardingExited, setOnboardingExited] = useState(false);
  const [error, setError] = useState('');
  // const [connectedAccountId, setConnectedAccountId] = useState();
  // const stripeConnectInstance = useStripeConnect(connectedAccountId);
  const [isSkipping, setIsSkipping] = useState(false);
  const [showMercuryForm, setShowMercuryForm] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const navigate = useNavigate();

  const handleSkipSetup = async () => {
    try {
      setIsSkipping(true);
      const response = await clientPost(URLS.CLIENT_SKIP_PAYOUT);
      if (response.ok) {
        setClient({ status: 'HOME' });
        navigate('/account');
      } else {
        setError('Failed to skip payout setup');
      }
    } catch (error) {
      setError(`Error skipping payout: ${error.message}`);
    } finally {
      setIsSkipping(false);
    }
  };

  // const createAccount = async () => {
  //   try {
  //     setAccountCreatePending(true);
  //     setError(false);
  //     const response = await clientPost(URLS.STRIPE_ACCOUNT);
  //     const json = await response.json();
  //     setAccountCreatePending(false);
  //     const { account, error } = json;
  //     if (account) {
  //       setConnectedAccountId(account);
  //     }
  //     if (error) {
  //       setError(true);
  //     }
  //   } catch (error) {
  //     setAccountCreatePending(false);
  //     setError(true);
  //   }
  // };

  // const exitOnboarding = async () => {
  //   try {
  //     setOnboardingExited(true);
  //     const response = await clientPost(URLS.CLIENT_FINISH_PAYOUT, {
  //       connectedAccountId,
  //     });
  //     if (response.ok) {
  //       setClient({ status: 'HOME' });
  //       navigate('/account');
  //     } else {
  //       setError('Failed to finish payout setup');
  //     }
  //   } catch (error) {
  //     setError(`Error exiting payout: ${error.message}`);
  //   }
  // };

  const handleMercurySubmit = async () => {
    try {
      // Trim and validate numbers first
      const trimmedAccount = accountNumber.trim();
      const trimmedRouting = routingNumber.trim();

      if (!/^\d{8,17}$/.test(trimmedAccount)) {
        setError('Account number must be 8-17 digits');
        return;
      }

      if (!/^\d{9}$/.test(trimmedRouting)) {
        setError('Routing number must be exactly 9 digits');
        return;
      }

      setIsAdding(true);
      const response = await clientPost(URLS.CLIENT_FINISH_PAYOUT_2, {
        type: 'mercury',
        accountNumber: trimmedAccount,
        routingNumber: trimmedRouting,
      });
      if (response.ok) {
        setClient({ status: 'HOME' });
        navigate('/account');
      } else {
        setError('Failed to set up Mercury payout');
      }
    } catch (error) {
      setError(`Error setting up Mercury payout: ${error.message}`);
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <div className="min-h-screen h-full p-6 bg-gray-900">
      <div className="max-w-4xl mx-auto p-6 bg-gray-800 rounded-lg shadow-lg space-y-6 border border-gray-700">
        <div className="bg-gray-700 p-4 rounded-lg text-center">
          <h2 className="text-2xl font-semibold text-blue-400">
            Select a Payout Method:
          </h2>
        </div>
        <div>
          {/* {connectedAccountId && !stripeConnectInstance ? (
            <h2 className="text-xl font-semibold text-gray-800">
              Add information to start accepting money
            </h2>
          ) : (
            !connectedAccountId && (
              <p className="text-gray-700 text-lg">
                Rhythm Wireless: Join our network of hosts and earn money!
              </p>
            )
          )} */}

          {/* {!accountCreatePending && !connectedAccountId && ( */}
          <div className="mt-4 space-y-4">
            {!showMercuryForm ? (
              <>
                <button
                  className="w-full py-3 px-6 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                  onClick={() => setShowMercuryForm(true)}
                >
                  Connect with Mercury
                </button>
                {/* <button
                    className="w-full py-3 px-6 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                    onClick={createAccount}
                  >
                    Connect with Stripe
                  </button> */}
              </>
            ) : (
              <div className="space-y-4">
                <div className="space-y-2">
                  <label
                    htmlFor="accountNumber"
                    className="block text-sm font-medium text-gray-300"
                  >
                    Account Number:
                  </label>
                  <input
                    id="accountNumber"
                    type="text"
                    placeholder="Account Number"
                    value={accountNumber}
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 17);
                      setAccountNumber(value);
                    }}
                    className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400"
                    maxLength={17}
                    pattern="\d*"
                    inputMode="numeric"
                  />
                </div>
                <div className="space-y-2">
                  <label
                    htmlFor="routingNumber"
                    className="block text-sm font-medium text-gray-300"
                  >
                    Routing Number:
                  </label>
                  <input
                    id="routingNumber"
                    type="text"
                    placeholder="Routing Number"
                    value={routingNumber}
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 9);
                      setRoutingNumber(value);
                    }}
                    className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400"
                    maxLength={9}
                    pattern="\d*"
                    inputMode="numeric"
                  />
                </div>
                <div className="flex space-x-4">
                  <button
                    className="flex-1 py-3 px-6 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                    onClick={handleMercurySubmit}
                    disabled={!accountNumber || !routingNumber || isAdding}
                  >
                    {isAdding ? (
                      <span className="flex items-center justify-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Adding...
                      </span>
                    ) : (
                      'Add Payout Method'
                    )}
                  </button>
                  <button
                    className="flex-1 py-3 px-6 bg-gray-600 hover:bg-gray-700 text-white font-semibold rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                    onClick={() => {
                      setShowMercuryForm(false);
                      setAccountNumber('');
                      setRoutingNumber('');
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* )} */}

          {/* {stripeConnectInstance && (
            <ConnectComponentsProvider
              className="stripe-provider"
              connectInstance={stripeConnectInstance}
            >
              <ConnectAccountOnboarding
                className="stripe-inner-provider"
                onExit={() => exitOnboarding()}
              />
            </ConnectComponentsProvider>
          )} */}

          {error && <p className="text-red-400 mt-2">{error}</p>}

          {/* {(connectedAccountId || accountCreatePending || onboardingExited) && (
            <div className="mt-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 rounded-lg">
              {accountCreatePending && (
                <p className="text-yellow-700">Creating a connected account...</p>
              )}
            </div>
          )} */}

          <button
            className="w-full py-3 px-6 mt-4 bg-gray-600 hover:bg-gray-700 text-white font-semibold rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-800"
            type="button"
            onClick={handleSkipSetup}
            disabled={isSkipping}
          >
            {isSkipping ? 'Setting up later...' : 'Set up later'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PayoutPage;
