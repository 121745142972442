import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clientPost } from '../../../utils';
import { URLS } from '../../../constants';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Onboarding.css';

const localizer = momentLocalizer(moment);

const SchedulingPage = ({ client, setClient }) => {
  const [selectedInstaller, setSelectedInstaller] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [appointmentScheduled, setAppointmentScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [availabilityLoading, setAvailabilityLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (client && client.installer) {
      const getInstallerMetadata = async () => {
        setAvailabilityLoading(true);
        try {
          const response = await clientPost(URLS.GET_INSTALLER_METADATA, {
            installer: client.installer,
          });
          if (response.ok) {
            const data = await response.json();
            setSelectedInstaller(data.installer);
          } else {
            console.error('Failed to fetch installer metadata from backend');
          }
        } catch (error) {
          console.error(
            'Error fetching installer metadata from backend:',
            error,
          );
        } finally {
          setAvailabilityLoading(false);
        }
      };

      getInstallerMetadata();
    }
  }, [client]);

  const formatEvents = (events) => {
    const currentTime = moment();
    const availableEvents = events.filter(
      (event) =>
        event.title !== 'Busy' && moment(event.start).isAfter(currentTime),
    );

    return availableEvents.map((event) => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
    }));
  };

  const isSelectable = (event) => {
    const { start } = event;
    const currentTime = moment();
    const startTime = moment(start);
    if (startTime.isBefore(currentTime)) {
      return false;
    }
    for (let i = 0; i < 6; i++) {
      const slotStart = startTime.clone().add(i * 30, 'minutes');
      const slotEnd = slotStart.clone().add(30, 'minutes');
      const slotEvent = selectedInstaller.availability.find(
        (e) =>
          moment(e.start).isSame(slotStart) &&
          moment(e.end).isSame(slotEnd) &&
          e.title !== 'Busy',
      );
      if (!slotEvent) return false;
    }
    return true;
  };

  const handleSelectEvent = (event) => {
    if (isSelectable(event)) {
      setSelectedEvent(event);
    } else {
      setSelectedEvent(null);
    }
  };

  const handleSubmit = async () => {
    if (selectedEvent) {
      setAppointmentScheduled(true);
      setScheduledDate(selectedEvent.start);
    }
  };

  const continueToDashboard = async () => {
    try {
      const response = await clientPost(URLS.CLIENT_FINISH_APPOINTMENT, {
        scheduledDate,
        scheduledDateString: scheduledDate.toString(),
        installerEmail: client.installer,
      });
      if (response.ok) {
        setClient({ status: 'PAYOUT' });
        navigate('/account');
      } else {
        console.error('Failed to finish client onboarding.');
      }
    } catch (error) {
      console.error('Failed to finish client onboarding.', error);
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = isSelectable(event) ? '#3174ad' : '#d3d3d3';

    if (selectedEvent) {
      const selectedStartTime = moment(selectedEvent.start);
      const selectedEndTime = selectedStartTime.clone().add(3, 'hours');

      if (
        moment(event.start).isBetween(
          selectedStartTime,
          selectedEndTime,
          null,
          '[)',
        )
      ) {
        return {
          style: {
            backgroundColor: 'darkgreen',
          },
        };
      }
    }

    return {
      style: {
        backgroundColor,
      },
    };
  };

  const hasValidAvailability = () => {
    if (
      !selectedInstaller.availability ||
      selectedInstaller.availability.length === 0
    ) {
      return false;
    }

    const currentTime = moment();
    return selectedInstaller.availability.some((event) => {
      const startTime = moment(event.start);
      return (
        event.title !== 'Busy' &&
        startTime.isAfter(currentTime) &&
        isSelectable({ start: event.start })
      );
    });
  };

  return (
    <div className="min-h-screen h-full p-6 bg-gray-900">
      <div className="max-w-6xl mx-auto p-6 bg-gray-800 rounded-lg shadow-lg space-y-6 border border-gray-700">
        {appointmentScheduled ? (
          <div className="bg-gray-700 p-4 rounded-lg shadow-md text-center">
            <p className="text-lg text-gray-300 mb-4">
              Your appointment will be scheduled for:{' '}
              {moment(scheduledDate).format('dddd, MMMM DD, YYYY, h:mm A')}
            </p>
            <div className="space-y-3">
              <button
                className="w-full py-3 px-6 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                onClick={continueToDashboard}
              >
                Continue
              </button>
              <button
                className="w-full py-3 px-6 bg-gray-600 hover:bg-gray-700 text-white font-semibold rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                onClick={() => {
                  setAppointmentScheduled(false);
                  setScheduledDate(null);
                  setSelectedEvent(null);
                }}
              >
                Back to Calendar
              </button>
            </div>
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-semibold text-blue-400">
              Schedule an installation (3-hour window):
            </h2>

            {!hasValidAvailability() && !availabilityLoading && (
              <div className="bg-gray-700 border-l-4 border-yellow-500 p-4 mb-4">
                <p className="text-gray-300">
                  Waiting for your installer to schedule availability. Please
                  refresh or return to this page later to check for updates. If
                  you need assistance, contact support at{' '}
                  <a
                    href="mailto:info@rhythmwireless.io"
                    className="text-blue-400 hover:text-blue-300 hover:underline"
                  >
                    info@rhythmwireless.io
                  </a>
                </p>
              </div>
            )}

            {selectedInstaller.availability && (
              <div className="h-[500px] bg-gray-700 rounded-lg shadow-inner p-4">
                <Calendar
                  localizer={localizer}
                  events={formatEvents(selectedInstaller.availability)}
                  defaultView="week"
                  views={['week', 'day']}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={handleSelectEvent}
                  eventPropGetter={eventStyleGetter}
                  selectable={false}
                  onSelectSlot={() => {}}
                  className="dark-calendar"
                />
              </div>
            )}
            <button
              className={`w-full py-3 px-6 mt-4 rounded-md text-white font-semibold focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800 ${
                !selectedEvent
                  ? 'bg-gray-600 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700'
              }`}
              onClick={handleSubmit}
              disabled={!selectedEvent}
            >
              Submit
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SchedulingPage;
