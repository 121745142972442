import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { adminPost } from '../../utils';
import { ROLE, URLS } from '../../constants';
import SmallSpinner from '../../components/SmallSpinner';

const PotentialBusinessClientsAdd = () => {
  const [formData, setFormData] = useState({
    businessName: '',
    businessTitle: '',
    fullName: '',
    businessEmail: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    dailyPatrons: '',
    squareFootage: '',
    referredBy: '',
    additionalNotes: '',
    readyForReview: false,
    skipOpsReview: false,
    opsUser: '',
    installer: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [allInstallers, setAllInstallers] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      setSubmitting(true);
      const response = await adminPost(URLS.ADD_POTENTIAL_BUSINESS, formData);
      if (!response.ok) {
        const body = await response.json();
        console.error(`Error adding potential business: ${body.error}`);
        setErrorMessage(`Error adding potential business: ${body.error}`);
        setSubmitting(false);
        return;
      }
      setSuccessMessage('Potential client succesfully added.');
    } catch (error) {
      setErrorMessage('Error adding potential business');
      console.error('Error adding potential business:', error);
    }
    setSubmitting(false);

    // Reset form fields
    setFormData({
      businessName: '',
      businessTitle: '',
      fullName: '',
      businessEmail: '',
      phoneNumber: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      dailyPatrons: '',
      squareFootage: '',
      referredBy: '',
      additionalNotes: '',
      readyForReview: false,
      skipOpsReview: false,
      opsUser: '',
      installer: '',
    });
  };

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await adminPost(URLS.GET_INSTALLERS);
        if (!response.ok) {
          console.error('Failed to fetch installers');
          return;
        }
        const data = await response.json();
        setAllInstallers(data.installers);
      } catch (error) {
        console.error('Error fetching installers:', error);
      }
    };
    fetchInstallers();
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <Link
        to="/admin/potential-business-clients"
        className="text-blue-600 hover:underline mb-6 block font-semibold"
      >
        Back To Potential Business Clients
      </Link>
      <h2 className="text-2xl font-bold mb-6 text-gray-800">
        Add Potential Business Client
      </h2>
      <form className="space-y-4" onSubmit={handleSubmit}>
        {/* Example Input Group */}
        <div className="flex flex-col">
          <label
            htmlFor="businessName"
            className="text-sm font-medium text-gray-700 mb-1"
          >
            Business Name
          </label>
          <input
            type="text"
            id="businessName"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter business name"
          />
        </div>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
          <div>
            <label
              htmlFor="businessTitle"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Business Title
            </label>
            <input
              type="text"
              id="businessTitle"
              name="businessTitle"
              value={formData.businessTitle}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter business title"
            />
          </div>
          <div>
            <label
              htmlFor="fullName"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Full Name
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter full name"
            />
          </div>
          <div>
            <label
              htmlFor="businessEmail"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Email
            </label>
            <input
              type="email"
              id="businessEmail"
              name="businessEmail"
              value={formData.businessEmail}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter email address"
            />
          </div>
          <div>
            <label
              htmlFor="phoneNumber"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Phone Number
            </label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter phone number"
            />
          </div>
          <div>
            <label
              htmlFor="address"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Street Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter street address"
            />
          </div>
          <div>
            <label
              htmlFor="city"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              City
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter city"
            />
          </div>
          <div>
            <label
              htmlFor="state"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              State
            </label>
            <input
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter state"
            />
          </div>
          <div>
            <label
              htmlFor="zipCode"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Zip Code
            </label>
            <input
              type="text"
              id="zipCode"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter zip code"
            />
          </div>
          <div>
            <label
              htmlFor="dailyPatrons"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Daily Patrons
            </label>
            <input
              type="text"
              id="dailyPatrons"
              name="dailyPatrons"
              value={formData.dailyPatrons}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter daily patrons"
            />
          </div>
          <div>
            <label
              htmlFor="squareFootage"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Square Footage
            </label>
            <input
              type="text"
              id="squareFootage"
              name="squareFootage"
              value={formData.squareFootage}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter square footage"
            />
          </div>
          <div>
            <label
              htmlFor="referredBy"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Referred By (Optional)
            </label>
            <input
              type="text"
              id="referredBy"
              name="referredBy"
              value={formData.referredBy}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="(Optional) Enter who referred the client"
            />
          </div>
        </div>
        {/* Example of a textarea */}
        <div className="flex flex-col">
          <label
            htmlFor="additionalNotes"
            className="text-sm font-medium text-gray-700 mb-1"
          >
            Sales Notes (Optional)
          </label>
          <textarea
            id="additionalNotes"
            name="additionalNotes"
            value={formData.additionalNotes}
            onChange={handleChange}
            className="border border-gray-300 rounded-md p-2 h-20 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="(Optional) Enter any additional notes for the Ops Team"
          ></textarea>
        </div>
        {/* Checkbox */}
        <div className="flex items-center space-x-3">
          <input
            type="checkbox"
            id="readyForReview"
            name="readyForReview"
            checked={formData.readyForReview}
            onChange={handleChange}
            className="h-5 w-5 text-blue-600 focus:ring-2 focus:ring-blue-500"
          />
          <label
            htmlFor="readyForReview"
            className="text-sm font-medium text-gray-700"
          >
            Ready for Review
          </label>
        </div>
        <div className="flex items-center space-x-3">
          <input
            type="checkbox"
            id="skipOpsReview"
            name="skipOpsReview"
            checked={formData.skipOpsReview}
            onChange={handleChange}
            className="h-5 w-5 text-blue-600 focus:ring-2 focus:ring-blue-500"
            disabled={!formData.opsUser || !formData.installer}
          />
          <label
            htmlFor="skipOpsReview"
            className="text-sm font-medium text-gray-700"
          >
            Skip Ops Review
          </label>
        </div>
        <div className="mb-6">
          <label
            htmlFor="opsUser"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Ops User:
          </label>
          <select
            id="opsUser"
            name="opsUser"
            value={formData.opsUser}
            onChange={handleChange}
            className="block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">Select Ops User</option>
            {allInstallers
              .filter((installer) => installer.role === ROLE.OPS)
              .map((installer) => (
                <option key={installer.email} value={installer.email}>
                  {installer.email}
                </option>
              ))}
          </select>
        </div>
        <div className="mb-6">
          <label
            htmlFor="installer"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Installer:
          </label>
          <select
            id="installer"
            name="installer"
            value={formData.installer}
            onChange={handleChange}
            className="block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="">Select Installer</option>
            {allInstallers.map((installer) => (
              <option key={installer.email} value={installer.email}>
                {installer.email}
              </option>
            ))}
          </select>
        </div>
        {/* Buttons */}
        {submitting ? (
          <div className="flex justify-center">
            <SmallSpinner />
          </div>
        ) : (
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
            disabled={
              !formData.fullName ||
              !formData.businessEmail ||
              !formData.phoneNumber ||
              !formData.address ||
              !formData.city ||
              !formData.state ||
              !formData.zipCode ||
              !formData.businessName ||
              !formData.businessTitle ||
              !formData.squareFootage ||
              !formData.dailyPatrons ||
              (formData.skipOpsReview &&
                (!formData.opsUser || !formData.installer)) ||
              (formData.skipOpsReview && formData.readyForReview)
            }
          >
            Submit
          </button>
        )}
        {/* Messages */}
        {errorMessage && (
          <p className="text-sm text-red-500 mt-2">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="text-sm text-green-500 mt-2">{successMessage}</p>
        )}
      </form>
    </div>
  );
};

export default PotentialBusinessClientsAdd;
